/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import './assets/scss/colors.scss';

* {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-family: 'Orbitron', 'sans-serif';
}

html {
  overflow-y: auto;
  overflow-x: hidden;
}

.h-100vh {
  min-height: 100vh;
}

// Scrollbar Styles
::-webkit-scrollbar {
  display: block;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: $gray-500;
}

::-webkit-scrollbar-thumb:hover {
  background: $gray-500;
}

// Toast styles
.toast-header {
  background-color: rgba($color: $gold, $alpha: 0.7);
  color: rgba($color: $gray, $alpha: 1);
}
